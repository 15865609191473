import { mainApi } from "services/main";
import { IContactRequest, IContactResponse } from "./definitions";

const extendedApi = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getContactsList: builder.query<IContactResponse, IContactRequest>({
      query: (request: IContactRequest) => ({
        url: "contacts/" + request.teamName,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useGetContactsListQuery, usePrefetch } = extendedApi;
