import moment from "moment";
import * as z from "zod";
import { isValidBrazilCnpj, isValidBrazilCpf } from "../utils/validation";
import { customAttributesDataObjectSchema } from "./generateCustomAttributesSchema";

export const zod = {
  string: () => z.string().trim().min(1, { message: "Required." }),
};
const invalidCharactersRegex = /["*/:<>?|]/;
const regexString = () =>
  z
    .string()
    .trim()
    .min(1, { message: "Required." })
    .refine((val) => !invalidCharactersRegex.test(val), {
      message: 'Input contains forbidden characters: "*/:<>?|',
    });

export const updateAuditStatusSchema = z.object({
  status: zod.string(),
  statusActionOwner: zod.string(),
  statusDetail: zod.string(),
});

export const VALID_INPUT_PATTERN =
  /^[A-Za-z0-9áàâãéèêíïóôõöúüçÁÀÂÃÉÈÊÍÏÓÔÕÖÚÜÇ .,&/()º™-]+$/;
export const createMarketplaceSellerDataSchema = z.object({
  reportName: regexString(),
  sellerName: z
    .string()
    .refine((data) => data.length === 0 || VALID_INPUT_PATTERN.test(data), {
      message: "Seller name contains invalid characters",
    }),
  sellerTaxId: z
    .string()
    .refine(
      (data) =>
        data.length === 0 || isValidBrazilCpf(data) || isValidBrazilCnpj(data),
      {
        message: "Seller id must be a CPF or CNPJ",
      }
    ),
  sellerId: z.string().optional(),
});

// Customer lookup form schema
export const encryptedidRegex = /^[A-Z0-9]+$/;
export const decryptedIdRegex = /^[0-9]+$/;

export const isValidId = (
  id: string,
  isDataEncrypted: string,
  searchType: string
): boolean => {
  if (searchType === "customerInfo") {
    return id.length >= 3 && decryptedIdRegex.test(id);
  } else {
    if (isDataEncrypted === "Yes")
      return id.length >= 3 && encryptedidRegex.test(id);
    return id.length >= 3 && decryptedIdRegex.test(id);
  }
};

export const createCustomerLookupDataSchema = z
  .object({
    reportName: regexString(),
    searchType: regexString(),
    idInput: z
      .string()
      .transform((value) =>
        value
          .split(",")
          .map((val) => val.trim())
          .filter((val) => val.length > 0)
          .join(",")
      )
      .optional(),
    // addressInfoInputIds: z.string().optional(),
    customerInfoSearchIdOption: z.string().optional(), // when user choose to search by customerInfo, we have two choices: buyerID and buyerExemptionId
    filesUploaded: z
      .array(z.instanceof(File))
      .optional()
      .refine(
        (value) => {
          if (value === undefined || value.length === 0) return true;
          return value[0].size < 10000000;
        },
        { message: "The file size is over the limit" }
      ),
    isDataEncrypted: regexString(),
  })
  .superRefine((data, ctx) => {
    if (data.idInput) {
      const ids = data.idInput
        .split(",")
        .map((id) => id.trim())
        .filter(Boolean);
      if (
        ids.length > 10 ||
        !ids.every((id) => isValidId(id, data.isDataEncrypted, data.searchType))
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["idInput"],
          message:
            "Maximum of 10 IDs allowed. Each encrypted ID should contain uppercase letters and digits only. Each decrypted ID should contain digits only.",
        });
      }
    }
  });

export const criticalDataUsageComponentSchema = z
  .object({
    reportPurpose: regexString(),
    isUserAgreed: z.boolean().refine((value) => value === true, {
      message: "You must accept the terms and conditions",
    }),
    currentUser: z.string(),
    reviewers: z
      .string({ required_error: "Must have at least one reviewer" })
      .array()
      .nonempty({ message: "Must have at least one reviewer" }),
  })
  .refine(
    (data) => {
      if (data.reviewers && data.reviewers.includes(data.currentUser)) {
        return false;
      }
      return true;
    },
    {
      message: "Reviewers must not contain the report owner",
      path: ["reviewers"],
    }
  );

export const createDataBrazilTransactionReportSchema = z
  .object({
    reportName: regexString(),
    entity: z.string().min(1, { message: "Required." }),
    state: zod.string(),
    startDate: zod.string(),
    endDate: zod.string(),
    sellerTaxId: z
      .string()
      .refine(
        (data) =>
          data.length > 0 &&
          (isValidBrazilCpf(data) || isValidBrazilCnpj(data)),
        {
          message: "Seller id must be a CPF or CNPJ",
        }
      ),
  })
  .superRefine((data, ctx) => {
    if (data.startDate > data.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["endDate"],
        message: "End date cannot be before start date",
      });
    }
  });

export const taskBasicSchema = z.object({
  taskId: zod.string().optional(),
  taskType: zod.string(),
  parentTaskId: zod.string(),
  teamName: zod.string(),
  taskName: regexString(),
  owners: zod.string(),
  status: z.string().optional(),
  actualStartDate: z.number().optional(),
  actualStartDateString: z.string().optional(),
  actualCompletionDate: z.number().optional(),
  actualCompletionDateString: z.string().optional(),
  milestone: z.boolean().optional(),
  description: z.string().optional(),
  deleted: z.boolean(),
  progress: z.string().optional(),
  taskGroupName: z.string().optional(),
  auditName: z.string().optional(),
  taskGroupType: z.string().nullable().optional(),
  inquiryType: z.string().nullable().optional(),
  subInquiryType: z.string().nullable().optional(),
  complexity: z.string().nullable().optional(),
  priority: z.string().nullable().optional(),
});

export const taskGroupSchema = taskBasicSchema.extend({
  plannedStartDate: z.number(),
  plannedStartDateString: zod.string(),
  dueDate: z.number(),
  dueDateString: zod.string(),
});

export const taskSchema = taskBasicSchema.extend({
  plannedStartDate: z.number().optional(),
  plannedStartDateString: z.string().optional(),
  dueDate: z.number().optional(),
  dueDateString: z.string().optional(),
});

export const createTaskSchema = taskSchema.omit({
  taskId: true,
  taskType: true,
  parentTaskId: true,
  teamName: true,
  plannedStartDate: true,
  actualStartDate: true,
  dueDate: true,
  actualCompletionDate: true,
  deleted: true,
  taskGroupType: true,
});

export const createTaskGroupSchema = taskGroupSchema
  .omit({
    deleted: true,
    owners: true,
    teamName: true,
    plannedStartDate: true,
    dueDate: true,
    actualStartDate: true,
    actualCompletionDate: true,
    parentTaskId: true,
    taskType: true,
    inquireType: true,
    subInquiryType: true,
    complexity: true,
    priority: true,
  })
  .refine(
    (input) => {
      if (
        moment(input.dueDateString).isAfter(
          moment(input.plannedStartDateString)
        )
      ) {
        return true;
      }
      return false;
    },
    {
      message: "Planned end date should be after planned start date",
      path: ["dueDateString"],
    }
  );

export const updateTaskSchema = taskSchema.omit({
  deleted: true,
  plannedStartDate: true,
  dueDate: true,
  actualStartDate: true,
  actualCompletionDate: true,
  taskGroupType: true,
});
export type UpdateTaskFormFields = z.infer<typeof updateTaskGroupSchema>;

export const updateTaskGroupSchema = taskGroupSchema
  .omit({
    deleted: true,
    owners: true,
    plannedStartDate: true,
    dueDate: true,
    actualStartDate: true,
    actualCompletionDate: true,
    parentTaskId: true,
    taskType: true,
    inquiryType: true,
    subInquiryType: true,
    complexity: true,
    priority: true,
  })
  .refine(
    (input) => {
      if (
        moment(input.dueDateString).isAfter(
          moment(input.plannedStartDateString)
        )
      ) {
        return true;
      }
      return false;
    },
    {
      message: "Planned end date should be after planned start date",
      path: ["dueDateString"],
    }
  );
export const commentSchema = z.object({
  commentId: zod.string(),
  parentId: zod.string(),
  actorId: zod.string(),
  actorType: zod.string(),
  attributes: z.record(z.string()),
  content: zod.string(),
  version: z.number(),
  creationTime: z.number(),
  application: zod.string(),
  responses: z.array(z.unknown()).optional(),
});

export const requestReviewSchema = z.object({
  reviewType: z.string({
    required_error: "Please select a review type",
  }),
  reviewers: z
    .string({ required_error: "Must have at least one reviewer" })
    .array()
    .nonempty({ message: "Must have at least one reviewer" })
    .max(5, { message: "Cannot have more than 5 reviewers" }),
  dueDateString: z.string({ required_error: "Due date is required" }),
  messageForReviewers: z.optional(
    z.string().max(2000, "Message must be 2000 characters or less")
  ),
});

export const editReviewSchema = z.object({
  reviewType: z.string({
    required_error: "Please select a review type",
  }),
  reviewers: z
    .string()
    .array()
    .max(5, { message: "Cannot have more than 5 reviewers" }),
  dueDateString: z.string({ required_error: "Due date is required" }),
  messageForReviewers: z.optional(
    z.string().max(2000, "Message must be 2000 characters or less")
  ),
});

export const reviewApprovalSchema = z
  .object({
    responseType: z
      .string({
        required_error: "Please select a response type",
      })
      .nonempty("Response type is required"),
    reassignReviewers: z
      .string()
      .array()
      .max(5, { message: "Cannot have more than 5 reviewers" })
      .optional(),
    comments: z.optional(
      z.string().max(2000, "Message must be 2000 characters or less")
    ),
  })
  .refine(
    (data) => {
      if (
        data.responseType === "Reassigned" &&
        data.reassignReviewers !== undefined &&
        data.reassignReviewers.length > 0
      ) {
        return true;
      } else if (
        data.responseType !== "Reassigned" &&
        (data.reassignReviewers == null || data.reassignReviewers?.length === 0)
      ) {
        return true;
      }
      return false;
    },
    {
      message: "Reviewer required",
      path: ["reassignReviewers"],
    }
  )
  .refine(
    (data) => {
      if (
        data.responseType === "Rejected" &&
        (data.comments == null || data.comments.trim().length === 0)
      ) {
        return false;
      }
      return true;
    },
    {
      message: "Rejection reason required",
      path: ["comments"],
    }
  );

export const reviewSchema = z.object({
  reviewId: zod.string(),
  auditId: z.string().optional(),
  taskGroupId: z.string().optional(),
  reviewParentId: zod.string(),
  reviewType: zod.string(),
  teamName: z.string().optional(),
  status: zod.string(),
  requestedBy: z.string(),
  reviewer: z.string(),
  deleted: z.boolean(),
  dueDate: z.number().optional(),
  modifiedAt: z.number().optional(),
  dueDateString: z.string(),
  reviewDate: z.number().optional(),
  auditName: z.string().optional(),
  taskGroupName: z.string().optional(),
  taskName: z.string().optional(),
  comments: z.string().optional(),
  messageForReviewers: z.string().optional(),
});

const teamSchema = z.object({
  teamId: z.string(),
  ldapGroupId: z.string(),
  teamDescription: z.string(),
  auditTitleFormula: z.string(),
  isCritical: z.boolean(),
  teamCustomAttributesSchema: z
    .record(
      z.string(),
      customAttributesDataObjectSchema
      // z.object({
      //   entityCustomAttributesSchemaList: customAttributesDataObjectSchema,
      // })
    )
    .optional(),
});

export const taxAreaSchema = z.object({
  taxAreaId: z.string(),
  teamName: z.string().optional(),
  title: z
    .string({
      required_error: "Title is required.",
    })
    .min(1, "Title is required")
    .max(255, "Title must be less than 255 characters"),
  category: z.string({
    required_error: "Category is required.",
  }),
  status: z.string(),
  description: z
    .string()
    .max(10000, "Description limited to 10000 characters")
    .optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().optional(),
});

export const createTaxAreaSchema = taxAreaSchema
  .omit({
    taxAreaId: true,
    teamName: true,
  })
  .refine(
    (data) => {
      if (
        data.title.length > 0 &&
        data.category.length > 0 &&
        data.status.length > 0
      ) {
        return true;
      } else return false;
    },
    {
      message: "Fields: title, category, and status are required",
      path: ["createTaxArea"],
    }
  );

export const updateTaxAreaSchema = taxAreaSchema
  .omit({
    taxAreaId: true,
    teamName: true,
  })
  .refine(
    (data) => {
      if (
        data.title.length > 0 &&
        data.category.length > 0 &&
        data.status.length > 0
      ) {
        return true;
      } else return false;
    },
    {
      message: "Fields: title, category, and status are required",
      path: ["createTaxArea"],
    }
  );

export const outcomeBreakdownSchema = z.object({
  outcomeBreakdownId: z.string(),
  parentId: z.string(),
  totalTaxAmount: regexString(),
  totalPenaltyAmount: regexString(),
  totalInterestAmount: regexString(),
  totalOtherAmount: regexString(),
  type: z.string(),
  category: z.string(),
});
export const createOutcomeBreakdownSchema = outcomeBreakdownSchema.omit({
  outcomeBreakdownId: true,
  parentId: true,
});

export enum OutcomeBreakdownType {
  TAXYEAR = "Tax year",
  TAXAREA = "Tax area",
}

export const assessmentSchema = z.object({
  assessmentId: z.string(),
  auditId: z.string(),
  type: z.string({ required_error: "Required" }),
  dateReceived: z
    .string({ required_error: "Required" })
    .refine((val) => val !== "", {
      message: "Required",
    }),
  totalTaxAmount: z.number({ required_error: "Total tax amount is required" }),
  totalPenaltyAmount: z.number({
    required_error: "Total penalty amount is required",
  }),
  totalInterestAmount: z.number({
    required_error: "Total interest amount is required",
  }),
  totalOtherAmount: z.number({
    required_error: "Total other amount is required",
  }),
  notes: z.string().max(1500, "Notes limited to 1500 characters").optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().optional(),
  taxYearBreakdown: z.array(outcomeBreakdownSchema).optional(),
  taxAreaBreakdown: z.array(outcomeBreakdownSchema).optional(),
});
export const createAssessmentSchema = assessmentSchema.omit({
  assessmentId: true,
  auditId: true,
});

export const paymentSchema = z.object({
  paymentId: z.string(),
  auditId: z.string(),
  type: z.string(),
  paymentDate: z.string().refine((val) => val !== "", {
    message: "Required",
  }),
  paymentRefNumber: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (!val) return true;
        return val.length === 7 && /^\d+$/.test(val);
      },
      {
        message: "Payment reference number must be exactly 7 digits",
      }
    ),
  trackingRefNumber: z
    .string()
    .optional()
    .refine(
      (val) => {
        if (!val) return true;
        return val.length === 20 && /^\d+$/.test(val);
      },
      {
        message: "Tracking reference number must be exactly 20 digits",
      }
    ),
  totalTaxAmount: z.number({ required_error: "Total tax amount is required" }),
  totalPenaltyAmount: z.number({
    required_error: "Total penalty amount is required",
  }),
  totalInterestAmount: z.number({
    required_error: "Total interest amount is required",
  }),
  totalOtherAmount: z.number({
    required_error: "Total other amount is required",
  }),
  notes: z.string().max(1500, "Notes limited to 1500 characters").optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  modifiedBy: z.string().optional(),
  modifiedAt: z.string().optional(),
  taxYearBreakdown: z.array(outcomeBreakdownSchema).optional(),
  taxAreaBreakdown: z.array(outcomeBreakdownSchema).optional(),
});
export const createPaymentSchema = paymentSchema.omit({
  paymentId: true,
  auditId: true,
});

export const documentSchema = z.object({
  documentId: zod.string(),
  parentId: zod.string(),
  extentionType: zod.string(),
  documentType: zod.string(),
  securityLevel: zod.string(),
  fileName: regexString(),
  teamName: zod.string(),
  deleted: z.boolean(),
  s3Prefix: z.string().optional(),
  size: z.string().optional(),
  externalUrl: z.string().optional(),
  createdBy: z.string().optional(),
  createdAt: z.string().optional(),
  updatedBy: z.string().optional(),
  updatedAt: z.string().optional(),
});

export const createDocumentSchema = z.object({
  documentType: zod.string(),
  fileName: z
    .string()
    .max(255, "Name limited to 255 characters")
    .refine((val) => !invalidCharactersRegex.test(val), {
      message: 'Input contains forbidden characters: "*/:<>?|',
    })
    .optional(),
});

export const documentTableSchema = z.object({
  documentId: zod.string(),
  fileName: regexString(),
  s3Prefix: z.string().optional(),
  size: z.string().optional(),
  externalUrl: z.string().optional(),
  documentType: z.string().optional(),
  updatedBy: z.string().optional(),
  updatedAt: z.string().optional(),
  extentionType: z.string().optional(),
  children: z.array(documentSchema),
});

export const contactFormSchema = z.object({
  fullName: regexString().refine((val) => /^[^"*/:<>?|]+$/.test(val), {
    message: "Invalid full name",
  }),
  jobTitle: z.string().optional(),
  email: z
    .string()
    .refine(
      (val) =>
        val === "" ||
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val),
      { message: "Invalid email address" }
    )
    .optional(),
  phoneNumber: z
    .string()
    .refine(
      (val) =>
        val === "" ||
        /^(\+\d{1,3}[\s.-]?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(val),
      "Invalid phone number"
    )
    .optional(),
  address: regexString().optional(),
  country: z.string().optional(),
  state: z.string().optional(),
  jurisdiction: regexString().optional(),
  notes: z.string().optional(),
  contractAuditor: z.boolean().optional(),
});

export type ContactType = z.infer<typeof contactFormSchema>;

export type CreateAssessmentFormFields = z.infer<typeof createAssessmentSchema>;

export type CreatePaymentFormFields = z.infer<typeof createPaymentSchema>;

export type CreateOutcomeBreakdownEntry = z.infer<
  typeof createOutcomeBreakdownSchema
>;

export type CreateTaskGroupFormFields = z.infer<typeof createTaskGroupSchema>;
export type UpdateTaskGroupFormFields = z.infer<typeof updateTaskGroupSchema>;

export type CreateTaxAreaFormFields = z.infer<typeof createTaxAreaSchema>;
export type UpdateTaxAreaFormFields = z.infer<typeof updateTaxAreaSchema>;

// Schema derived types
export type Assessment = z.infer<typeof assessmentSchema>;
export type Payment = z.infer<typeof paymentSchema>;
export type OutcomeBreakdown = z.infer<typeof outcomeBreakdownSchema>;
export type Task = z.infer<typeof taskSchema> & { createdAt?: number };
export type Comment = z.infer<typeof commentSchema>;
export type Review = z.infer<typeof reviewSchema>;
export type Team = z.infer<typeof teamSchema>;
export type TeamNonCustomAttributes = Omit<Team, "teamCustomAttributesSchema">;
export type TaxArea = z.infer<typeof taxAreaSchema>;

export type Document = z.infer<typeof documentSchema>;
export type CreateDocumentFields = z.infer<typeof createDocumentSchema>;
export type documentTable = z.infer<typeof documentTableSchema>;
