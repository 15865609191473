import { Box } from "@amzn/awsui-components-react";

interface SelectedOption {
  label?: string;
  children?: string | any;
}

export const ValueWithLabel = (selectedOption: SelectedOption) => (
  <div>
    <Box variant="awsui-key-label">{selectedOption.label}</Box>
    <div>{selectedOption.children}</div>
  </div>
);
