import React from "react";
import { ICommonDeleteModalProps } from "./definitions";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import styles from "./style.module.scss";

const CommonDeleteModal: React.FC<ICommonDeleteModalProps> = (
  props: ICommonDeleteModalProps
) => {
  return (
    <Modal
      onDismiss={() => props.setVisible(false)}
      visible={props.visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.setVisible(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                props.items && props.onDelete(props.items);
                props.setVisible(false);
              }}
            >
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={props.title}
    >
      <SpaceBetween direction="vertical" size="m">
        <Box></Box>
        <Box>{props.message}</Box>
      </SpaceBetween>
    </Modal>
  );
};

export default CommonDeleteModal;
