import React, { useEffect, useState } from "react";
import Page from "components/page";
import { ITeamSettingsProps, ListTaxAreaRequest } from "./definitions";
import styles from "./style.module.scss";
import { Help } from "./Help";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  Tabs,
} from "@amzn/awsui-components-react";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import { useAppDispatch, useAppSelector } from "app/store";
import { useGetTeamByIdQuery } from "./endpoints";
import { GetTeamByIdRequest } from "./definitions";
import { useBreadcrumbs } from "features/breadcrumbs";
import { setTeamById, removeSelectedTeam } from "features/team-settings/slice";
import ApiIndicator from "components/api-indicator";
import { useTeamNameSelector } from "./hooks";
import TaxAreaTable from "components/taxarea-table/TaxAreaTable";
import ContactsTable from "components/contacts-table";
import { ValueWithLabel } from "utils/value-with-label";

const TeamSettings: React.FC<ITeamSettingsProps> = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [activeTabId, setActiveTabId] = React.useState(
    searchParams.get("tabId") || "overview"
  );
  const { teamName } = useTeamNameSelector();

  const { updateBreadcrumbs } = useBreadcrumbs();

  //permissions link
  function permissionsLink() {
    const ldapGroupId = selectedTeam.team ? selectedTeam.team.ldapGroupId : "";
    const permissionsTeamName = ldapGroupId
      .replace("test-fintech-gaia-", "GaiaTest_")
      .replace("fintech-gaia-", "Gaia_")
      .concat("_Team");
    return "https://permissions.amazon.com/a/team/" + permissionsTeamName;
  }

  //Overview tab
  const request: GetTeamByIdRequest = {
    teamId: teamName as string,
    teamName: teamName as string,
  };

  const { data, isLoading, isError, error } = useGetTeamByIdQuery(request, {
    refetchOnMountOrArgChange: true,
    skip: !teamName,
  });

  const selectedTeam: any = useAppSelector(
    (state) => state.teamSettings.selectedTeam
  );

  useEffect(() => {
    updateBreadcrumbs &&
      updateBreadcrumbs([
        { text: "Gaia", href: "/" },
        {
          text: `${data?.teamDescription || ""}`,
          href: "#",
        },
      ]);
  }, [data?.teamDescription]);

  useEffect(() => {
    if (data) {
      dispatch(setTeamById(data));
    }
    return () => {
      dispatch(removeSelectedTeam());
    };
  }, [dispatch, data]);

  const Overview = (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <SpaceBetween direction="vertical" size="xl">
        <Container header={<Header> Team information </Header>}>
          <br />
          <ColumnLayout columns={3} variant="text-grid">
            <ValueWithLabel label="Team name">
              {selectedTeam.team?.teamDescription}
            </ValueWithLabel>
            <ValueWithLabel label="Audit title formula">
              {selectedTeam.team?.auditTitleFormula}
            </ValueWithLabel>
            <ValueWithLabel label="Permissions">
              <Link
                external={true}
                ariaLabel="Link"
                target="_blank"
                href={permissionsLink()}
              >
                Link
              </Link>
            </ValueWithLabel>
          </ColumnLayout>
        </Container>
      </SpaceBetween>
    </Grid>
  );

  //Tax Areas tab
  const TaxAreas = <TaxAreaTable name="Tax Area Table" />;
  const Contacts = (
    <ContactsTable
      actions={
        <SpaceBetween direction="horizontal" size="m">
          <Button>Delete</Button>
          <Button
            variant="primary"
            onClick={() => {
              navigate("/team/create-contact");
            }}
          >
            Create
          </Button>
        </SpaceBetween>
      }
    />
  );

  const navigate = useNavigate();

  return (
    <div>
      <ApiIndicator
        isLoading={isLoading}
        isError={isError}
        isAlertError={true}
        error={error}
      />
      <Page
        type="page"
        title={selectedTeam.team?.teamDescription}
        hasBreadcrumbs={true}
        hasInfo={false}
        help={<Help />}
        overlapWithHeader={true}
      >
        <Tabs
          onChange={({ detail }) => {
            setActiveTabId(detail.activeTabId);
            navigate(`?tabId=${detail.activeTabId}`);
          }}
          activeTabId={activeTabId}
          tabs={[
            {
              label: <span className={styles.tabText}>Overview</span>,
              id: "overview",
              content: Overview,
            },
            {
              label: <span className={styles.tabText}>Tax areas</span>,
              id: "tax_areas",
              content: TaxAreas,
            },
            // {
            //   label: <span className={styles.tabText}>Contacts</span>,
            //   id: "contacts",
            //   content: Contacts,
            // },
          ]}
        />
      </Page>
    </div>
  );
};

export default TeamSettings;
