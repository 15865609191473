import React from "react";
import { ITableItemStatusProps } from "./definitions";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

const TableItemStatus: React.FC<ITableItemStatusProps> = ({ stringStatus }) => {
  switch (stringStatus) {
    //status for data query
    case "yet to start":
      return <StatusIndicator type={"pending"}>Yet to start</StatusIndicator>;
    case "pending approval":
      return (
        <StatusIndicator type={"pending"}>Pending approval</StatusIndicator>
      );
    case "running":
      return (
        <StatusIndicator type={"in-progress"}>In progress</StatusIndicator>
      );
    case "error":
      return <StatusIndicator type={"error"}>Error</StatusIndicator>;
    case "rejected":
      return <StatusIndicator type={"error"}>Rejected</StatusIndicator>;
    case "success":
      return <StatusIndicator>Complete</StatusIndicator>;

    //status for task/audit
    case "Not started":
      return <StatusIndicator type={"pending"}>Not started</StatusIndicator>;
    case "In progress":
      return (
        <StatusIndicator type={"in-progress"}>In progress</StatusIndicator>
      );
    case "On hold":
      return <StatusIndicator type={"error"}>On hold</StatusIndicator>;
    case "Complete":
      return <StatusIndicator>Complete</StatusIndicator>;
    case "No status found":
      return (
        <StatusIndicator type={"warning"}>No status found</StatusIndicator>
      );
    //status for reviews and approval
    case "Pending":
      return <StatusIndicator type={"pending"}>Pending</StatusIndicator>;
    case "Rejected":
      return <StatusIndicator type={"error"}>Rejected</StatusIndicator>;
    case "Approved":
      return <StatusIndicator type={"success"}>Approved</StatusIndicator>;
    case "Skipped":
      return <StatusIndicator type={"stopped"}>Skipped</StatusIndicator>;
    default:
      return (
        <StatusIndicator type={"in-progress"}>In progress</StatusIndicator>
      );
    //status for tax areas
    case "Active":
      return <StatusIndicator type={"success"}>Active</StatusIndicator>;
    case "Inactive":
      return <StatusIndicator type={"stopped"}>Inactive</StatusIndicator>;
  }
};

export default TableItemStatus;
